import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, Phone, Mail, MapPin, ArrowRight, X, 
  Clock, ChevronDown
} from 'lucide-react';

const ContactPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [openFaq, setOpenFaq] = useState(null);

  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };




  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  
  const location = useLocation();




  const faqs = [
    {
      question: "How quickly can you start managing my property?",
      answer: "We can typically begin managing your property within 2-3 weeks of signing our management agreement. This allows time for proper documentation and setup of your account."
    },
    {
      question: "What areas do you cover?",
      answer: "We currently manage properties throughout Greater London and surrounding areas. Contact us to check if we cover your specific location."
    },
    {
      question: "What are your management fees?",
      answer: "Our management fees vary based on the services required and property type. We offer competitive rates starting from 8% of rental income for full management services."
    },
    {
      question: "Do you handle maintenance and repairs?",
      answer: "Yes, we have a network of trusted contractors and handle all aspects of property maintenance, from routine repairs to emergency situations."
    },
    {
      question: "How do you screen potential tenants?",
      answer: "We conduct thorough background checks including credit history, employment verification, previous landlord references, and identity verification."
    },
    {
      question: "What reports do landlords receive?",
      answer: "Landlords receive monthly financial statements, maintenance reports, and regular property inspection updates through our online portal."
    }
  ];

  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      {showNotification && (
        <div className="fixed top-4 right-4 z-50 animate-slide-in-right">
          <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
            <span>Thanks for your message! We'll be in touch soon.</span>
            <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
              <X size={18} />
            </button>
          </div>
        </div>
      )}

{/* Header */}
<header className={`bg-white border-b border-[#eae7e6] sticky top-0 z-40 transition-transform duration-300 ${
  isHidden ? '-translate-y-full' : 'translate-y-0'
}`}>    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center h-20">
        {/* Logo */}
        <div className="w-40"> 
          <Link to="/" className="flex items-center group cursor-pointer">
            <img 
              src="/header-logo.png" 
              alt="Nova Asset Management" 
              className="h-12 transform group-hover:scale-105 transition-transform duration-200"
            />
          </Link>
        </div>
        {/* Centered Desktop Navigation */}
        <nav className="hidden md:flex flex-1 justify-center">
          <div className="flex space-x-12">
            {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
              <Link
                key={item}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium transition-colors duration-200 py-2 ${
                  (item === "Home" && location.pathname === "/") || 
                  (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                    ? "text-[#b2a5a1]" 
                    : ""
                }`}
                onMouseEnter={() => setHoveredLink(item)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item}
                <span 
                  className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] transform origin-left transition-transform duration-200 ${
                    ((item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                    hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                  }`}
                />
              </Link>
            ))}
          </div>
        </nav>
        {/* Mobile menu button */}
        <div className="w-40 flex justify-end">
          <button 
            className="md:hidden relative group p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="flex flex-col space-y-1.5">
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>
        </div>
      </div>
    </div>

    {/* Mobile Navigation */}
    <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-64' : 'max-h-0'}`}>
      <div className="px-4 py-3 space-y-2">
        {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
          <Link
            key={item}
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
              (item === "Home" && location.pathname === "/") || 
              (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                ? "text-[#b2a5a1] bg-[#f7f5f5]"
                : "text-[#8c7f7c]"
            }`}
            onClick={() => setIsMenuOpen(false)}
          >
            {item}
          </Link>
        ))}
      </div>
    </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c]">
        {/* Hero Section */}
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-white sm:text-5xl mb-6">Get in Touch</h1>
              <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
                Our property management experts are here to help with your inquiries
              </p>
            </div>

            {/* Contact Methods */}
            <div className="mt-16 grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                { icon: Phone, text: "+44 (0)20 1234 5678", label: "Call us" },
                { icon: Mail, text: "info@novaml.co.uk", label: "Email us" },
                { icon: Clock, text: "Mon-Fri: 9am-6pm", label: "Office Hours" },
                { icon: MapPin, text: "123 Property Street, London", label: "Visit us" },
              ].map((item, i) => (
                <div 
                  key={i} 
                  className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20 text-center transform hover:scale-105 transition-all duration-200"
                >
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 rounded-lg">
                      <item.icon className="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <h3 className="text-lg font-semibold text-white mb-2">{item.label}</h3>
                  <p className="text-neutral-200">{item.text}</p>
                </div>
              ))}
            </div>

            {/* Contact Form and FAQ Section */}
            <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12">
              {/* Form */}
              <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
                <h2 className="text-2xl font-bold text-white mb-6">Send us a Message</h2>
                <form onSubmit={(e) => { e.preventDefault(); triggerNotification(); }} className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-neutral-200 mb-1">First Name</label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-neutral-400 focus:outline-none focus:border-white/40"
                        placeholder="John"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-neutral-200 mb-1">Last Name</label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-neutral-400 focus:outline-none focus:border-white/40"
                        placeholder="Doe"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-neutral-200 mb-1">Email</label>
                    <input
                      type="email"
                      className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-neutral-400 focus:outline-none focus:border-white/40"
                      placeholder="john@example.com"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-neutral-200 mb-1">Message</label>
                    <textarea
                      rows="4"
                      className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-neutral-400 focus:outline-none focus:border-white/40"
                      placeholder="How can we help you?"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full px-6 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 flex items-center justify-center group"
                  >
                    Send Message
                    <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </form>
              </div>

              {/* FAQ Section */}
              <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
                <h2 className="text-2xl font-bold text-white mb-6">Frequently Asked Questions</h2>
                <div className="space-y-4">
                  {faqs.map((faq, index) => (
                    <div key={index} className="border-b border-white/10 last:border-0">
                      <button
                        className="w-full py-4 flex items-center justify-between text-left"
                        onClick={() => setOpenFaq(openFaq === index ? null : index)}
                      >
                        <span className="text-white font-medium">{faq.question}</span>
                        <ChevronDown 
                          className={`h-5 w-5 text-white transform transition-transform duration-200 ${
                            openFaq === index ? 'rotate-180' : ''
                          }`}
                        />
                      </button>
                      <div className={`overflow-hidden transition-all duration-200 ${
                        openFaq === index ? 'max-h-40 pb-4' : 'max-h-0'
                      }`}>
                        <p className="text-neutral-200">{faq.answer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <footer className="bg-[#2c2827] border-t border-[#3d3533]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {[
              { title: "Company", links: ["About", "Careers", "Contact"] },
              { title: "Services", links: ["Property Management", "Lettings", "Block Management"] },
              { title: "Legal", links: ["Privacy", "Terms"] },
              { title: "Social", links: ["LinkedIn", "Twitter", "Facebook"] }
            ].map((section, i) => (
              <div key={i}>
                <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">{section.title}</h3>
                <ul className="mt-4 space-y-4">
                  {section.links.map((link, j) => (
                    <li key={j}>
                      <a 
                        href="#" 
                        className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                      >
                        <span>{link}</span>
                        <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-[#3d3533] pt-8">
            <p className="text-base text-[#968a87]">&copy; 2024 Nova Asset Management Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ContactPage;