import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, Award, UserCheck, Landmark, ArrowRight, Users,
  Target, Sparkles, HandshakeIcon, Star, X, PoundSterling,
  Laptop, Headset, MessageSquare, Settings, Phone, Mail, MapPin,
  ChevronUp, Clipboard, BarChart3, GitCommit
} from 'lucide-react';

const AboutPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const triggerNotification = (message = "Thanks for your interest! We'll be in touch soon.") => {
    setShowNotification(message);
    setTimeout(() => setShowNotification(false), 3000);
  };


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  
  const location = useLocation();



  const team = [
    {
      name: "Sarah Thompson",
      role: "Managing Director",
      desc: "15+ years of property management experience",
      image: "/api/placeholder/120/120",
      linkedin: "#"
    },
    {
      name: "Michael Roberts",
      role: "Head of Property Management",
      desc: "Specializes in residential block management",
      image: "/api/placeholder/120/120",
      linkedin: "#"
    },
    {
      name: "Emma Wilson",
      role: "Client Relations Manager",
      desc: "Dedicated to exceptional client service",
      image: "/api/placeholder/120/120",
      linkedin: "#"
    },
    {
      name: "David Chen",
      role: "Operations Director",
      desc: "Expert in property maintenance and logistics",
      image: "/api/placeholder/120/120",
      linkedin: "#"
    }
  ];

  // Navigation items with sections
  const navItems = [
    { name: "Overview", section: "overview" },
    { name: "Process", section: "process" },
    { name: "Team", section: "team" },
    { name: "Values", section: "values" },
    { name: "Contact", section: "contact" }
  ];

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      {showNotification && (
        <div className="fixed top-4 right-4 z-50 animate-slide-in-right">
          <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
            <span>{showNotification}</span>
            <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
              <X size={18} />
            </button>
          </div>
        </div>
      )}

      {/* Scroll to top button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

      {/* Section Navigation */}
      <div className="hidden lg:block fixed left-8 top-1/2 transform -translate-y-1/2 z-40">
        <div className="space-y-4">
          {navItems.map((item) => (
            <button
              key={item.section}
              onClick={() => scrollToSection(item.section)}
              className={`flex items-center space-x-2 text-sm ${
                activeSection === item.section ? 'text-[#b2a5a1]' : 'text-[#8c7f7c]'
              } hover:text-[#b2a5a1] transition-colors duration-200`}
            >
              <div className={`h-px w-8 transition-all duration-200 ${
                activeSection === item.section ? 'w-12 bg-[#b2a5a1]' : 'w-8 bg-[#8c7f7c]'
              }`} />
              <span>{item.name}</span>
            </button>
          ))}
        </div>
      </div>

{/* Header */}
<header className={`bg-white border-b border-[#eae7e6] sticky top-0 z-40 transition-transform duration-300 ${
  isHidden ? '-translate-y-full' : 'translate-y-0'
}`}>    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center h-20">
        {/* Logo */}
        <div className="w-40"> 
          <Link to="/" className="flex items-center group cursor-pointer">
            <img 
              src="/header-logo.png" 
              alt="Nova Asset Management" 
              className="h-12 transform group-hover:scale-105 transition-transform duration-200"
            />
          </Link>
        </div>
        {/* Centered Desktop Navigation */}
        <nav className="hidden md:flex flex-1 justify-center">
          <div className="flex space-x-12">
            {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
              <Link
                key={item}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium transition-colors duration-200 py-2 ${
                  (item === "Home" && location.pathname === "/") || 
                  (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                    ? "text-[#b2a5a1]" 
                    : ""
                }`}
                onMouseEnter={() => setHoveredLink(item)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item}
                <span 
                  className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] transform origin-left transition-transform duration-200 ${
                    ((item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                    hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                  }`}
                />
              </Link>
            ))}
          </div>
        </nav>
        {/* Mobile menu button */}
        <div className="w-40 flex justify-end">
          <button 
            className="md:hidden relative group p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="flex flex-col space-y-1.5">
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>
        </div>
      </div>
    </div>

    {/* Mobile Navigation */}
    <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-64' : 'max-h-0'}`}>
      <div className="px-4 py-3 space-y-2">
        {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
          <Link
            key={item}
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
              (item === "Home" && location.pathname === "/") || 
              (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                ? "text-[#b2a5a1] bg-[#f7f5f5]"
                : "text-[#8c7f7c]"
            }`}
            onClick={() => setIsMenuOpen(false)}
          >
            {item}
          </Link>
        ))}
      </div>
    </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c]">
        {/* Hero Section */}
        <section id="overview" className="relative py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-white sm:text-5xl mb-6 animate-fade-in-up delay-100">
                About Nova Asset Management
              </h1>
              <p className="text-xl text-neutral-200 max-w-2xl mx-auto animate-fade-in-up delay-200">
                A leading property asset management company dedicated to delivering exceptional service and maximizing property value.
              </p>
            </div>

            {/* Stats with animation */}
            <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
              {[
                { value: "50+", label: "Years Experience Combined", icon: Award },
                { value: "500+", label: "Properties Managed", icon: Building2 },
                { value: "98%", label: "Client Retention", icon: UserCheck },
                { value: "24/7", label: "Support Available", icon: Headset }
              ].map((stat, i) => (
                <div 
                  key={i} 
                  className="text-center transform hover:scale-105 transition-transform duration-200 animate-fade-in-up"
                  style={{ animationDelay: `${i * 100}ms` }}
                >
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 rounded-lg">
                      <stat.icon className="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
                  <div className="text-neutral-200">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

{/* Our Story */}
<div className="py-24 bg-white/5">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-white mb-6">Our Story</h2>
        <p className="text-lg text-neutral-200 mb-8">
          Founded in 2024, Nova Asset Management has rapidly emerged as a dynamic force in UK property asset management. Our journey focuses on optimizing rental properties through a combination of long-term tenancies, short-term rentals, and luxury serviced lodgings. This targeted strategy ensures our clients receive the highest possible returns, establishing us as industry leaders.
        </p>
        <p className="text-lg text-neutral-200 mb-8">
          We are committed to supporting our clients by providing advance rent paid through direct debit on the first of every month, ensuring every investment is secured with a guaranteed income. With our assets under management growing rapidly and approaching significant milestones, we continuously improve our service offering to take advantage of future opportunities.
        </p>
        <div className="text-xl font-semibold text-white mb-8">
          "To Grow Wealth Together"
        </div>
      </div>

      {/* Stats Section - Updated to match overview section */}
      <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
        {[
          { value: "50+", label: "Years Experience Combined", icon: Award },
          /*{ value: "£800M+", label: "Portfolio Value", icon: Award },*/
          { value: "500+", label: "Properties Managed", icon: Building2 },
          { value: "98%", label: "Client Retention", icon: UserCheck },
          { value: "24/7", label: "Support Available", icon: Headset }
        ].map((stat, i) => (
          <div 
            key={i} 
            className="text-center transform hover:scale-105 transition-transform duration-200 animate-fade-in-up"
            style={{ animationDelay: `${i * 100}ms` }}
          >
            <div className="flex justify-center mb-4">
              <div className="p-3 bg-white/10 rounded-lg">
                <stat.icon className="h-6 w-6 text-white" />
              </div>
            </div>
            <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
            <div className="text-neutral-200">{stat.label}</div>
          </div>
        ))}
      </div>


      {/* Service Approach */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          {
            title: "Long-term Tenancies",
            desc: "Stable, reliable income through carefully vetted long-term tenants"
          },
          {
            title: "Short-term Rentals",
            desc: "Maximizing returns through strategic short-term letting opportunities"
          },
          {
            title: "Luxury Serviced Lodgings",
            desc: "Premium service for high-end properties and discerning clients"
          }
        ].map((service, i) => (
          <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
            <h3 className="text-xl font-semibold text-white mb-3">{service.title}</h3>
            <p className="text-neutral-200">{service.desc}</p>
          </div>
        ))}
      </div>
    </div>
  </div>

{/* Our Process */}
<div className="py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center mb-16">
      <h2 className="text-3xl font-bold text-white mb-4">
        Our Consultation Process
      </h2>
      <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
        We follow a structured approach to ensure we deliver the best property management solutions
      </p>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
{/* Left side - Process explanation */}
<div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <p className="text-neutral-200 mb-8">
          At Nova Asset Management, our consultation process is meticulously designed to maximize the potential of your property investments. We combine industry expertise with innovative solutions to deliver exceptional results through our proven four-step approach.
        </p>
        <div className="space-y-6">
          {[
            {
              title: "Portfolio Assessment",
              desc: "Comprehensive evaluation of your properties and investment goals",
              icon: Clipboard
            },
            {
              title: "Financial Analysis",
              desc: "Detailed market research and revenue optimization strategies",
              icon: BarChart3
            },
            {
              title: "Strategy Development",
              desc: "Customized management plans tailored to your objectives",
              icon: Target
            },
            {
              title: "Implementation Plan",
              desc: "Clear timelines and milestones for seamless execution",
              icon: GitCommit
            }
          ].map((item, i) => (
            <div key={i} className="flex items-start space-x-4 p-4 rounded-lg hover:bg-white/5 transition-colors duration-200">
              <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white/10 rounded-lg">
                <item.icon className="h-5 w-5 text-white" />
              </span>
              <div>
                <h4 className="text-white font-medium mb-1">{item.title}</h4>
                <p className="text-neutral-200 text-sm">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right side - Process steps */}
      <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <h3 className="text-2xl font-bold text-white mb-6">Step-by-Step Process</h3>
        <div className="space-y-8">
          {[
            {
              step: "01",
              title: "Initial Consultation",
              desc: "We assess your property portfolio and understand your management needs"
            },
            {
              step: "02",
              title: "Custom Strategy",
              desc: "Develop a tailored management plan aligned with your objectives"
            },
            {
              step: "03",
              title: "Implementation",
              desc: "Seamless transition and setup of management services"
            },
            {
              step: "04",
              title: "Ongoing Support",
              desc: "Regular reviews and continuous optimization of services"
            }
          ].map((item, i) => (
            <div key={i} className="relative pl-14">
              <span className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-full bg-white/10 text-white font-bold">
                {item.step}
              </span>
              <div>
                <h4 className="text-lg font-semibold text-white">{item.title}</h4>
                <p className="mt-1 text-neutral-200">{item.desc}</p>
              </div>
              {i < 3 && (
                <div className="absolute left-5 top-10 h-full w-px bg-white/20" />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Bottom CTA */}
      <div className="lg:col-span-2 mt-8 text-center">
        <Link
          to="/contact"
          className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
        >
          Schedule a Consultation
          <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
        </Link>
      </div>
    </div>
  </div>
</div>      

{/* Why Choose Us */}
<div className="py-24 bg-white/5">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center mb-16">
      <h2 className="text-3xl font-bold text-white mb-4">Why Choose Nova Asset Management</h2>
      <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
        Industry expertise combined with innovative solutions
      </p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[
        {
          title: "Industry Expertise",
          desc: "Over 15 years of combined experience in property asset management",
          icon: Building2
        },
        {
          title: "Financial Security",
          desc: "Guaranteed rental income with advance payments through direct debit",
          icon: PoundSterling
        },
        {
          title: "Innovative Technology",
          desc: "Advanced property management software and reporting tools",
          icon: Laptop
        },
        {
          title: "Dedicated Support",
          desc: "Round-the-clock property care and emergency response",
          icon: Headset
        },
        {
          title: "Transparent Communication",
          desc: "Regular updates and detailed performance reports",
          icon: MessageSquare
        },
        {
          title: "Tailored Solutions",
          desc: "Customized management strategies for your portfolio",
          icon: Settings
        }
      ].map((item, i) => (
        <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
          <div className="flex justify-center mb-4">
            <div className="p-3 bg-white/10 rounded-lg">
              <item.icon className="h-8 w-8 text-white" />
            </div>
          </div>
          <h3 className="text-xl font-semibold text-white text-center mb-2">
            {item.title}
          </h3>
          <p className="text-neutral-200 text-center">
            {item.desc}
          </p>
        </div>
      ))}
    </div>
  </div>
</div>

        {/* Team */}
        <div className="py-24 bg-white/5">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <h2 className="text-3xl font-bold text-white text-center mb-16">Our Leadership Team</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {team.map((member, i) => (
                <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
                  <div className="flex justify-center mb-4">
                    <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-white/20">
                      <img 
                        src={member.image} 
                        alt={member.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-white text-center mb-1">{member.name}</h3>
                  <p className="text-neutral-200 text-center font-medium mb-2">{member.role}</p>
                  <p className="text-neutral-300 text-center text-sm">{member.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Values */}
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <h2 className="text-3xl font-bold text-white text-center mb-16">Our Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: Target,
                  title: "Excellence",
                  desc: "Striving for the highest standards in everything we do"
                },
                {
                  icon: HandshakeIcon,
                  title: "Integrity",
                  desc: "Building trust through transparent and ethical practices"
                },
                {
                  icon: Sparkles,
                  title: "Innovation",
                  desc: "Embracing new technologies and solutions"
                },
                {
                  icon: Users,
                  title: "Partnership",
                  desc: "Working closely with clients to achieve their goals"
                }
              ].map((value, i) => (
                <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 rounded-lg">
                      <value.icon className="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-white text-center mb-2">{value.title}</h3>
                  <p className="text-neutral-200 text-center">{value.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to Work Together?</h2>
            <p className="text-xl text-neutral-200 mb-8">
              Let us help you achieve your property management goals
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
            >
              Get in Touch
              <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </div>

{/* Contact */}
<div className="bg-[#2c2827]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {[
              { icon: Phone, text: "+44 (0)20 1234 5678" },
              { icon: Mail, text: "info@novaml.co.uk" },
              { icon: MapPin, text: "123 Property Street, London, UK" },
            ].map((item, i) => (
              <button
                key={i}
                onClick={() => triggerNotification()}
                className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group"
              >
                <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
                <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">{item.text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#2c2827] border-t border-[#3d3533]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {[
              { title: "Company", links: ["About", "Careers", "Contact"] },
              { title: "Services", links: ["Property Management", "Lettings", "Block Management"] },
              { title: "Legal", links: ["Privacy", "Terms"] },
              { title: "Social", links: ["LinkedIn", "Twitter", "Facebook"] }
            ].map((section, i) => (
              <div key={i}>
                <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">{section.title}</h3>
                <ul className="mt-4 space-y-4">
                  {section.links.map((link, j) => (
                    <li key={j}>
                      <a 
                        href="#" 
                        className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                      >
                        <span>{link}</span>
                        <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-[#3d3533] pt-8">
            <p className="text-base text-[#968a87]">&copy; 2024 Nova Asset Management Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage;