import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PropertyWebsite from './HomePage';
import AboutPage from './AboutPage';
import ServicesPage from './ServicesPage';
import PropertiesPage from './PropertiesPage';
import ContactPage from './ContactPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PropertyWebsite />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/properties" element={<PropertiesPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;