import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, 
  Home,
  Key,
  Users,
  ClipboardCheck,
  ArrowRight,
  Star,
  Wrench,
  Shield,
  PoundSterling,
  X,
  Phone,
  Mail,
  MapPin,
  ChevronDown
} from 'lucide-react';

const ServicesPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  
  const location = useLocation();


  const services = [
    {
      icon: Home,
      title: "Property Management",
      desc: "Complete management services for your property portfolio",
      features: [
        "24/7 Property Support",
        "Tenant Management",
        "Regular Property Inspections",
        "Maintenance Coordination",
        "Financial Reporting",
        "Online Portal Access"
      ]
    },
    {
      icon: Key,
      title: "Lettings",
      desc: "Professional letting services for landlords and tenants",
      features: [
        "Tenant Screening",
        "Property Marketing",
        "Viewings Management",
        "Contract Preparation",
        "Deposit Protection",
        "Rent Collection"
      ]
    },
    {
      icon: Users,
      title: "Block Management",
      desc: "Specialized services for residential blocks and estates",
      features: [
        "Service Charge Management",
        "Contractor Coordination",
        "Resident Communication",
        "Building Insurance",
        "Major Works Planning",
        "Health & Safety Compliance"
      ]
    },
    {
      icon: ClipboardCheck,
      title: "Maintenance",
      desc: "Regular maintenance and emergency repairs",
      features: [
        "24/7 Emergency Response",
        "Planned Maintenance",
        "Contractor Management",
        "Quality Control",
        "Cost Management",
        "Maintenance Reporting"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

      {/* Header */}
<header className={`bg-white border-b border-[#eae7e6] sticky top-0 z-40 transition-transform duration-300 ${
  isHidden ? '-translate-y-full' : 'translate-y-0'
}`}>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-40"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo.png" 
            alt="Nova Asset Management" 
            className="h-12 transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>
      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium transition-colors duration-200 py-2 flex items-center ${
                  (item === "Home" && location.pathname === "/") || 
                  (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                    ? "text-[#b2a5a1]" 
                    : ""
                }`}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] transform origin-left transition-transform duration-200 ${
                    ((item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                    hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                  }`}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div 
                  className={`absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 bg-white rounded-lg shadow-lg border border-[#eae7e6] transition-all duration-200 ${
                    openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'
                  }`}
                >
                  <div className="p-4 space-y-3">
                    {[
                      { icon: Home, title: "Property Management" },
                      { icon: Key, title: "Lettings" },
                      { icon: Users, title: "Block Management" },
                      { icon: ClipboardCheck, title: "Maintenance" }
                    ].map((service, index) => (
                      <Link
                        key={index}
                        to="/services"
                        className="flex items-center space-x-3 px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                        onClick={() => setOpenDropdown(false)}
                      >
                        <service.icon className="w-5 h-5" />
                        <span className="font-medium">{service.title}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Mobile menu button */}
      <div className="w-40 flex justify-end">
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

  {/* Mobile Navigation */}
  <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
    <div className="px-4 py-3 space-y-2">
      {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
        <div key={item}>
          <Link
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
              (item === "Home" && location.pathname === "/") || 
              (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                ? "text-[#b2a5a1] bg-[#f7f5f5]"
                : "text-[#8c7f7c]"
            }`}
            onClick={() => {
              if (item === "Services") {
                setOpenDropdown(!openDropdown);
              } else {
                setIsMenuOpen(false);
              }
            }}
          >
            <div className="flex items-center justify-center">
              {item}
              {item === "Services" && (
                <ChevronDown className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${openDropdown ? 'rotate-180' : ''}`} />
              )}
            </div>
          </Link>
          
          {/* Mobile Services Dropdown */}
          {item === "Services" && (
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-64' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { icon: Home, title: "Property Management" },
                  { icon: Key, title: "Lettings" },
                  { icon: Users, title: "Block Management" },
                  { icon: ClipboardCheck, title: "Maintenance" }
                ].map((service, index) => (
                  <Link
                    key={index}
                    to="/services"
                    className="flex items-center space-x-3 px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    onClick={() => {
                      setIsMenuOpen(false);
                      setOpenDropdown(false);
                    }}
                  >
                    <service.icon className="w-5 h-5" />
                    <span className="font-medium">{service.title}</span>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
</header>

      {/* Main Content */}
      <div className="py-24 bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white sm:text-5xl">Our Services</h1>
            <p className="mt-4 text-xl text-neutral-200 max-w-2xl mx-auto">
              Comprehensive property management solutions tailored to your needs
            </p>
          </div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, i) => (
              <div
                key={i}
                className="relative group cursor-pointer"
                onMouseEnter={() => setActiveService(i)}
                onMouseLeave={() => setActiveService(null)}
              >
                {/* Border glow */}
                <div className="absolute -inset-0.5 bg-white opacity-0 group-hover:opacity-30 rounded-lg blur transition duration-300"></div>

                {/* Card content */}
                <div className="relative bg-white/10 backdrop-blur-lg p-6 rounded-lg shadow-lg border border-white/20 transition-all duration-300 h-full">
                  <div className="flex justify-center">
                    <service.icon className="h-12 w-12 text-white transform group-hover:scale-110 transition-transform duration-300" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold text-white text-center">{service.title}</h3>
                  <p className="mt-2 text-neutral-200 text-center">{service.desc}</p>

                  <div className={`mt-4 space-y-2 transition-all duration-300 ${activeService === i ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0'} overflow-hidden`}>
                    {service.features.map((feature, j) => (
                      <div key={j} className="flex items-center text-sm text-neutral-200">
                        <Star className="h-4 w-4 text-white mr-2" />
                        {feature}
                      </div>
                    ))}
                    <button
                      onClick={() => triggerNotification()}
                      className="mt-4 w-full px-4 py-2 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 flex items-center justify-center group"
                    >
                      Learn More
                      <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Why Choose Us */}
          <div className="mt-24 bg-white/10 backdrop-blur-lg rounded-lg p-8 border border-white/20">
            <h2 className="text-3xl font-bold text-white text-center mb-12">Why Choose Nova Asset Management</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: Shield,
                  title: "Professional Excellence",
                  desc: "Certified property management experts with years of experience"
                },
                {
                  icon: Wrench,
                  title: "Responsive Service",
                  desc: "24/7 support and swift resolution of property issues"
                },
                {
                  icon: PoundSterling,
                  title: "Cost Effective",
                  desc: "Transparent pricing and optimized property maintenance"
                },
                {
                  icon: Users,
                  title: "Client Focused",
                  desc: "Personalized service tailored to your specific needs"
                }
              ].map((item, i) => (
                <div key={i} className="text-center p-6">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 rounded-lg">
                      <item.icon className="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <h3 className="text-lg font-semibold text-white mb-2">{item.title}</h3>
                  <p className="text-neutral-200">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="mt-24 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to Get Started?</h2>
            <p className="text-xl text-neutral-200 mb-8">
              Contact us today to discuss your property management needs
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
            >
              Contact Us
              <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </div>

{/* Contact */}
<div className="bg-[#2c2827]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {[
              { icon: Phone, text: "+44 (0)20 1234 5678" },
              { icon: Mail, text: "info@novaml.co.uk" },
              { icon: MapPin, text: "123 Property Street, London, UK" },
            ].map((item, i) => (
              <button
                key={i}
                onClick={() => triggerNotification()}
                className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group"
              >
                <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
                <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">{item.text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#2c2827] border-t border-[#3d3533]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {[
              { title: "Company", links: ["About", "Careers", "Contact"] },
              { title: "Services", links: ["Property Management", "Lettings", "Block Management"] },
              { title: "Legal", links: ["Privacy", "Terms"] },
              { title: "Social", links: ["LinkedIn", "Twitter", "Facebook"] }
            ].map((section, i) => (
              <div key={i}>
                <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">{section.title}</h3>
                <ul className="mt-4 space-y-4">
                  {section.links.map((link, j) => (
                    <li key={j}>
                      <a 
                        href="#" 
                        className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                      >
                        <span>{link}</span>
                        <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-[#3d3533] pt-8">
          <p className="text-base text-[#968a87]">&copy; 2024 Nova Asset Management Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ServicesPage;