import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, 
  Phone, 
  Mail, 
  MapPin,
  Home,
  Key,
  Users,
  ClipboardCheck,
  ChevronRight,
  ArrowRight,
  Star,
  X,
  Laptop,
  Headset,
  PoundSterling,
  Quote,
  ChevronLeft,
  User2
} from 'lucide-react';

const PropertyWebsite = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  
  const triggerNotification = (message) => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const properties = [
    { 
      title: "Luxury Riverside Apartments",
      type: "Residential Block",
      location: "London Docklands",
      desc: "Premium waterfront development featuring 124 luxury apartments with stunning river views",
      stats: {
        units: "124 Units",
        occupancy: "98%",
        managed: "Since 2020"
      },
      features: ["24/7 Concierge", "Gym & Spa", "Secure Parking", "Riverside Gardens"],
      img: "metro-gateway-apartment-homes-riverside-ca-building-photo.jpg",
      propertyType: "residential",
      status: "Fully Managed"
    },
    {
      title: "The Victoria Commercial Centre",
      type: "Commercial Complex",
      location: "Manchester City Centre",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      stats: {
        units: "45 Units",
        occupancy: "95%",
        managed: "Since 2019"
      },
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "/api/placeholder/400/320",
      propertyType: "commercial",
      status: "Block Management"
    },
    {
      title: "Heritage Court Development",
      type: "Mixed Use",
      location: "Birmingham",
      desc: "Historic conversion featuring luxury apartments and boutique retail spaces in the heart of the city",
      stats: {
        units: "86 Units",
        occupancy: "97%",
        managed: "Since 2021"
      },
      features: ["Period Features", "Courtyard Garden", "Secure Entry", "Retail Space"],
      img: "/api/placeholder/400/320",
      propertyType: "mixed",
      status: "Full Management"
    }
  ];
  const services = [
    {
      icon: Home,
      title: "Property Management",
      desc: "Complete management services for your property portfolio",
      features: ["24/7 Support", "Online Portal", "Regular Inspections"]
    },
    {
      icon: Key,
      title: "Lettings",
      desc: "Professional letting services for landlords and tenants", 
      features: ["Tenant Screening", "Contract Management", "Rent Collection"]
    },
    {
      icon: Users,
      title: "Block Management",
      desc: "Specialized services for residential blocks and estates",
      features: ["Communal Maintenance", "Service Charge", "Building Insurance"] 
    },
    {
      icon: ClipboardCheck,
      title: "Maintenance",
      desc: "Regular maintenance and emergency repairs",
      features: ["Emergency Response", "Planned Maintenance", "Quality Control"]
    }
  ];


const [activeIndex, setActiveIndex] = useState(0);


const testimonials = [
  {
    text: "Nova Asset Management has transformed how we manage our residential portfolio. Their proactive maintenance approach has reduced our operational costs by 23% while improving tenant satisfaction. The online portal makes tracking property performance incredibly straightforward.",
    author: "Sarah Thompson",
    position: "Property Portfolio Manager",
    company: "London Residential Investments",
    rating: 5,
    propertyType: "Multi-unit Residential",
    duration: "Client since 2021",
    portfolio: "85 units managed",
    image: "blank-profile-picture-973460_1280.webp"
  },
  {
    text: "The level of professionalism and attention to detail from Nova Asset Management is exceptional. They've streamlined our service charge management and improved communication with residents. Their block management expertise has been invaluable for our development.",
    author: "Michael Stewart",
    position: "Director",
    company: "Highland Developments Ltd",
    rating: 5,
    propertyType: "Mixed-use Development",
    duration: "Client since 2020",
    portfolio: "3 blocks, 142 units",
    image: "/api/placeholder/120/120"
  },
  {
    text: "Since switching to Nova Asset Management, our property's occupancy rates have increased to 98%. Their tenant screening process is thorough, and their maintenance response times are impressive. The quarterly reports provide excellent insights for our investors.",
    author: "Emma Blackwood",
    position: "Investment Manager",
    company: "Crown Property Group",
    rating: 5,
    propertyType: "Commercial Properties",
    duration: "Client since 2019",
    portfolio: "12 commercial units",
    image: "/api/placeholder/120/120"
  },
  {
    text: "The transition to Nova Asset Management's management services was seamless. Their team's expertise in handling our mixed-use development has resulted in significant improvements in both tenant satisfaction and operational efficiency.",
    author: "James Richardson",
    position: "Development Director",
    company: "Urban Living Spaces",
    rating: 5,
    propertyType: "Mixed-use Development",
    duration: "Client since 2022",
    portfolio: "4 developments, 230 units",
    image: "/api/placeholder/120/120"
  },
  {
    text: "Nova Asset Management's block management service has exceeded our expectations. Their transparent communication and efficient handling of maintenance issues has greatly improved our residents' satisfaction levels.",
    author: "Victoria Chen",
    position: "Board Chairman",
    company: "The Riverside Apartments",
    rating: 5,
    propertyType: "Residential Block",
    duration: "Client since 2020",
    portfolio: "1 block, 156 units",
    image: "/api/placeholder/120/120"
  }
];


const [isHidden, setIsHidden] = useState(false);
const [lastScrollY, setLastScrollY] = useState(0);

useEffect(() => {
  const controlHeader = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
    
    setLastScrollY(currentScrollY);
  };

  window.addEventListener('scroll', controlHeader);
  return () => window.removeEventListener('scroll', controlHeader);
}, [lastScrollY]);

const location = useLocation();

  return (
    <div className="min-h-screen bg-neutral-50">
      
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

{/* Header */}
<header className={`bg-white border-b border-[#eae7e6] sticky top-0 z-40 transition-transform duration-300 ${
  isHidden ? '-translate-y-full' : 'translate-y-0'
}`}>    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center h-20">
        {/* Logo */}
        <div className="w-40"> 
          <Link to="/" className="flex items-center group cursor-pointer">
            <img 
              src="/header-logo.png" 
              alt="Nova Asset Management" 
              className="h-12 transform group-hover:scale-105 transition-transform duration-200"
            />
          </Link>
        </div>
        {/* Centered Desktop Navigation */}
        <nav className="hidden md:flex flex-1 justify-center">
          <div className="flex space-x-12">
            {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
              <Link
                key={item}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium transition-colors duration-200 py-2 ${
                  (item === "Home" && location.pathname === "/") || 
                  (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                    ? "text-[#b2a5a1]" 
                    : ""
                }`}
                onMouseEnter={() => setHoveredLink(item)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item}
                <span 
                  className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] transform origin-left transition-transform duration-200 ${
                    ((item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                    hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                  }`}
                />
              </Link>
            ))}
          </div>
        </nav>
        {/* Mobile menu button */}
        <div className="w-40 flex justify-end">
          <button 
            className="md:hidden relative group p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="flex flex-col space-y-1.5">
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>
        </div>
      </div>
    </div>

    {/* Mobile Navigation */}
    <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-64' : 'max-h-0'}`}>
      <div className="px-4 py-3 space-y-2">
        {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
          <Link
            key={item}
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
              (item === "Home" && location.pathname === "/") || 
              (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                ? "text-[#b2a5a1] bg-[#f7f5f5]"
                : "text-[#8c7f7c]"
            }`}
            onClick={() => setIsMenuOpen(false)}
          >
            {item}
          </Link>
        ))}
      </div>
    </div>
</header>

{/* Hero Section */}
<div className="relative bg-gradient-to-br from-[#8c7f7c] via-[#b2a5a1] to-[#8c7f7c] overflow-hidden">
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }}
        />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 py-24 lg:py-32">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            {/* Left side - Main content */}
            <div className="text-left">
              <h1 className="text-4xl font-bold text-white sm:text-5xl md:text-6xl">
                Nova Asset<br />Management
              </h1>
              <p className="mt-6 text-xl text-neutral-200 max-w-lg">
                Your trusted partner in property asset management. We offer comprehensive solutions for property owners, investors, and residential blocks.
              </p>
              <div className="mt-8 space-y-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-white/10">
                      <Building2 className="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h2 className="text-lg font-semibold text-white">Professional Management</h2>
                    <p className="mt-1 text-neutral-200">Expert handling of your property portfolio</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-white/10">
                      <ClipboardCheck className="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h2 className="text-lg font-semibold text-white">Compliance Assured</h2>
                    <p className="mt-1 text-neutral-200">Stay compliant with all regulations</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-white/10">
                      <Users className="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h2 className="text-lg font-semibold text-white">24/7 Support</h2>
                    <p className="mt-1 text-neutral-200">Round-the-clock property care</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side - Service Process (Moved from below) */}
            <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
              <h3 className="text-2xl font-bold text-white mb-6">Our Process</h3>
              <div className="space-y-8">
                {[
                  {
                    step: "01",
                    title: "Initial Consultation",
                    desc: "We assess your property portfolio and understand your management needs"
                  },
                  {
                    step: "02",
                    title: "Custom Strategy",
                    desc: "Develop a tailored management plan aligned with your objectives"
                  },
                  {
                    step: "03",
                    title: "Implementation",
                    desc: "Seamless transition and setup of management services"
                  },
                  {
                    step: "04",
                    title: "Ongoing Support",
                    desc: "Regular reviews and continuous optimization of services"
                  }
                ].map((item, i) => (
                  <div key={i} className="relative pl-14">
                    <span className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-full bg-white/10 text-white font-bold">
                      {item.step}
                    </span>
                    <div>
                      <h4 className="text-lg font-semibold text-white">{item.title}</h4>
                      <p className="mt-1 text-neutral-200">{item.desc}</p>
                    </div>
                    {i < 3 && (
                      <div className="absolute left-5 top-10 h-full w-px bg-white/20" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>


{/* Services */}
<div className="bg-[#8c7f7c] py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center">
      <h2 className="text-3xl font-bold text-white sm:text-4xl">
        Comprehensive Solutions
      </h2>
      <p className="mt-4 text-xl text-neutral-200 max-w-2xl mx-auto">
        Expert property management services tailored to your needs  
      </p>
    </div>

    {/* Service Categories */}
    <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
      {services.map((service, i) => (
        <div
          key={i} 
          className="relative group cursor-pointer"
          onMouseEnter={() => setActiveService(i)} 
          onMouseLeave={() => setActiveService(null)}
        >
          {/* Border glow container */}
          <div className="absolute -inset-0.5 bg-white opacity-0 group-hover:opacity-30 rounded-lg blur transition duration-300"></div>
          
          {/* Card content */}
          <div className="relative bg-white/10 backdrop-blur-lg p-6 rounded-lg shadow-lg border border-white/20 transition-all duration-300 h-full">
            <div className="flex justify-center">
              <service.icon className="h-12 w-12 text-white transform group-hover:scale-110 transition-transform duration-300" />
            </div>
            <h3 className="mt-4 text-lg font-semibold text-white">{service.title}</h3>
            <p className="mt-2 text-neutral-200">{service.desc}</p>

            <div className={`mt-4 space-y-2 transition-all duration-300 ${activeService === i ? 'opacity-100 max-h-40' : 'opacity-0 max-h-0'} overflow-hidden`}>
              {service.features.map((feature, j) => (
                <div key={j} className="flex items-center text-sm text-neutral-200">
                  <Star className="h-4 w-4 text-white mr-2" />
                  {feature}
                </div>  
              ))}
            </div>
          </div>
        </div>
      ))} 
    </div>

    {/* Detailed Service Information */}
    <div className="mt-24 grid grid-cols-1 lg:grid-cols-2 gap-12">
      {/* Left Column - Why Choose Us + CTA */}
      <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <h3 className="text-2xl font-bold text-white mb-6">Why Choose Nova Asset Management</h3>
        <div className="space-y-6">
          {[
            {
              title: "Industry Expertise",
              desc: "Over 20 years of combined experience in property asset management",
              icon: Building2
            },
            {
              title: "Technology-Driven",
              desc: "Advanced property management software and reporting tools",
              icon: Laptop
            },
            {
              title: "Dedicated Support",
              desc: "24/7 emergency response and dedicated property managers",
              icon: Headset
            },
            {
              title: "Transparent Pricing",
              desc: "Clear fee structure with no hidden costs",
              icon: PoundSterling
            }
          ].map((item, i) => (
            <div key={i} className="flex items-start">
              <div className="flex-shrink-0">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-white/10">
                  <item.icon className="h-6 w-6 text-white" />
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg font-semibold text-white">{item.title}</h4>
                <p className="mt-1 text-neutral-200">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section - Moved inside Why Choose Us */}
        <div className="mt-8 pt-8 border-t border-white/20">
          <div className="text-center">
            <h3 className="text-2xl font-bold text-white">Ready to get started?</h3>
            <p className="mt-2 text-neutral-200 mb-6">
              Let us help you optimize your property portfolio
            </p>
            <button
              onClick={() => triggerNotification()}
              className="px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 flex items-center justify-center mx-auto group"
            >
              Schedule a Consultation
              <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>

      {/* Right Column - Contact Form */}
      <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <h2 className="text-2xl font-bold text-white mb-6">Get Started Today</h2>
        <form onSubmit={(e) => { e.preventDefault(); triggerNotification(); }} className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-white mb-1">First Name</label>
              <input
                type="text"
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
                placeholder="John"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white mb-1">Last Name</label>
              <input
                type="text"
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
                placeholder="Doe"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-white mb-1">Email</label>
            <input
              type="email"
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
              placeholder="john@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white mb-1">Phone</label>
            <input
              type="tel"
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
              placeholder="+44 (0)20 1234 5678"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white mb-1">Property Type</label>
            <select className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-white/20">
              <option value="" className="text-neutral-800">Select property type</option>
              <option value="residential" className="text-neutral-800">Residential Property</option>
              <option value="commercial" className="text-neutral-800">Commercial Property</option>
              <option value="mixed" className="text-neutral-800">Mixed Use</option>
              <option value="block" className="text-neutral-800">Residential Block</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-white mb-1">Message</label>
            <textarea
              rows="3"
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
              placeholder="Tell us about your property management needs..."
            ></textarea>
          </div>
          <div className="flex items-center">
            <input type="checkbox" className="h-4 w-4 border-white/10 rounded" />
            <label className="ml-2 text-sm text-white">
              I agree to receive communications from Nova Asset Management
            </label>
          </div>
          <button
            type="submit"
            className="w-full px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 flex items-center justify-center group"
          >
            Submit Request
            <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
      

{/* Featured Properties */}
<div className="py-24 bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c]">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center mb-16">
      <h2 className="text-3xl font-bold text-white sm:text-4xl mb-4">
        Featured Properties
      </h2>
      <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
        Discover our portfolio of expertly managed properties across the UK
      </p>
    </div>

    {/* Property Type Filters */}
    <div className="flex justify-center gap-4 mb-12">
      {["All", "Residential", "Commercial", "Mixed Use"].map((type) => (
        <button
          key={type}
          className="px-6 py-2 rounded-full text-sm font-medium transition-all duration-200
            border border-white/20 text-white hover:bg-white/10 backdrop-blur-sm"
        >
          {type}
        </button>
      ))}
    </div>

    <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
      {properties.map((property, i) => (
        <div 
          key={i}
          className="group cursor-pointer bg-white/10 backdrop-blur-lg rounded-xl shadow-md border border-white/20 overflow-hidden hover:shadow-xl transition-all duration-300"
        >
          {/* Image Container */}
          <div className="relative">
            <div className="relative pb-[65%] overflow-hidden">
              <img 
                src={property.img}
                alt={property.title}
                className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            {/* Property Type Badge */}
            <div className="absolute top-4 left-4">
              <span className="px-3 py-1 bg-white/90 backdrop-blur-sm text-[#8c7f7c] text-sm font-medium rounded-full">
                {property.type}
              </span>
            </div>
            {/* Status Badge */}
            <div className="absolute top-4 right-4">
              <span className="px-3 py-1 bg-[#b2a5a1]/90 backdrop-blur-sm text-white text-sm font-medium rounded-full">
                {property.status}
              </span>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h3 className="text-xl font-semibold text-white group-hover:text-neutral-200 transition-colors duration-200">
                  {property.title}
                </h3>
                <p className="text-neutral-200 flex items-center mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {property.location}
                </p>
              </div>
            </div>

            <p className="text-neutral-200 mb-6">{property.desc}</p>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-4 mb-6">
              {Object.entries(property.stats).map(([key, value]) => (
                <div key={key} className="text-center p-2 bg-white/5 rounded-lg">
                  <p className="text-sm font-medium text-white">{value}</p>
                  <p className="text-xs text-neutral-300 capitalize">{key}</p>
                </div>
              ))}
            </div>

            {/* Features */}
            <div className="mb-6">
              <div className="flex flex-wrap gap-2">
                {property.features.map((feature, index) => (
                  <span 
                    key={index}
                    className="px-3 py-1 bg-white/10 text-neutral-200 text-sm rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4">
              <button 
                onClick={() => triggerNotification(`You're interested in ${property.title}!`)}
                className="flex-1 px-4 py-2 bg-white text-[#8c7f7c] text-sm font-medium rounded-lg hover:bg-white/90 transition-colors duration-200 flex items-center justify-center group"
              >
                View Details
                <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
              <button 
                onClick={() => triggerNotification('Property brochure request sent!')}
                className="px-4 py-2 border border-white/20 text-white text-sm font-medium rounded-lg hover:bg-white/10 transition-colors duration-200"
              >
                Download Brochure
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* View All Properties CTA */}
    <div className="mt-16 text-center">
      <button className="inline-flex items-center px-8 py-3 border-2 border-white/20 text-white font-medium rounded-lg hover:bg-white/10 transition-all duration-200 group">
        View All Properties
        <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  </div>
</div>

{/* Testimonials */}
<div className="bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white sm:text-4xl mb-4">
              Client Success Stories
            </h2>
            <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
              Discover how we've helped property owners and managers achieve their goals
            </p>
          </div>

          {/* Featured Testimonial */}
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className={`relative bg-white/10 backdrop-blur-lg rounded-2xl p-8 lg:p-12 shadow-xl border border-white/20 transition-opacity duration-500 ${
                activeIndex === index ? 'opacity-100' : 'hidden'
              }`}
            >
              <Quote className="absolute top-8 left-8 h-12 w-12 text-white/20" />
              
              <div className="grid lg:grid-cols-3 gap-8 items-center">
                {/* Left Column - Author Info */}
                <div className="text-center lg:text-left">
                  <div className="flex items-center justify-center lg:justify-start mb-4">
                    <div className="relative">
                      <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-white/20">
                        <img 
                          src={testimonial.image}
                          alt={testimonial.author}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-white">
                    {testimonial.author}
                  </h3>
                  <p className="text-neutral-200 mt-1">
                    {testimonial.position}
                  </p>
                  <p className="text-neutral-300 text-sm">
                    {testimonial.company}
                  </p>
                  <div className="flex items-center justify-center lg:justify-start mt-3">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                    ))}
                  </div>
                </div>

                {/* Middle Column - Main Quote */}
                <div className="lg:col-span-2">
                  <p className="text-lg text-white leading-relaxed">
                    {testimonial.text}
                  </p>
                  
                  <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-4">
                    <div className="bg-white/5 rounded-lg p-4">
                      <Building2 className="h-5 w-5 text-white/60 mb-2" />
                      <p className="text-sm text-white/80">{testimonial.propertyType}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-4">
                      <User2 className="h-5 w-5 text-white/60 mb-2" />
                      <p className="text-sm text-white/80">{testimonial.duration}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-4">
                      <Building2 className="h-5 w-5 text-white/60 mb-2" />
                      <p className="text-sm text-white/80">{testimonial.portfolio}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Navigation */}
          <div className="mt-8 flex justify-center items-center space-x-4">
            <button 
              onClick={() => setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
              className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-200"
            >
              <ChevronLeft className="h-6 w-6 text-white" />
            </button>
            
            {/* Dots */}
            <div className="flex space-x-2">
              {testimonials.map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => setActiveIndex(idx)}
                  className={`w-2 h-2 rounded-full transition-all duration-200 ${
                    activeIndex === idx ? 'bg-white w-4' : 'bg-white/50'
                  }`}
                />
              ))}
            </div>

            <button 
              onClick={() => setActiveIndex((prev) => (prev + 1) % testimonials.length)}
              className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-200"
            >
              <ChevronRight className="h-6 w-6 text-white" />
            </button>
          </div>

          {/* Stats */}
          <div className="mt-16 grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: "Properties Managed", value: "500+" },
              { label: "Client Satisfaction", value: "98%" },
              { label: "Years Experience", value: "15+" },
              { label: "Client Retention", value: "95%" }
            ].map((stat, i) => (
              <div key={i} className="text-center">
                <p className="text-3xl font-bold text-white mb-2">{stat.value}</p>
                <p className="text-neutral-200">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

{/* Contact */}
<div className="bg-[#2c2827]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {[
              { icon: Phone, text: "+44 (0)20 1234 5678" },
              { icon: Mail, text: "info@novaml.co.uk" },
              { icon: MapPin, text: "123 Property Street, London, UK" },
            ].map((item, i) => (
              <button
                key={i}
                onClick={() => triggerNotification()}
                className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group"
              >
                <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
                <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">{item.text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#2c2827] border-t border-[#3d3533]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {[
              { title: "Company", links: ["About", "Careers", "Contact"] },
              { title: "Services", links: ["Property Management", "Lettings", "Block Management"] },
              { title: "Legal", links: ["Privacy", "Terms"] },
              { title: "Social", links: ["LinkedIn", "Twitter", "Facebook"] }
            ].map((section, i) => (
              <div key={i}>
                <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">{section.title}</h3>
                <ul className="mt-4 space-y-4">
                  {section.links.map((link, j) => (
                    <li key={j}>
                      <a 
                        href="#" 
                        className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                      >
                        <span>{link}</span>
                        <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-[#3d3533] pt-8">
            <p className="text-base text-[#968a87]">&copy; 2024 Nova Asset Management Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PropertyWebsite;