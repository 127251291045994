import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Building2, 
  MapPin,
  ArrowRight,
  X
} from 'lucide-react';

const PropertiesPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [selectedType, setSelectedType] = useState('All');

  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  
  const location = useLocation();


  const properties = [
    { 
      title: "Luxury Riverside Apartments",
      type: "Residential",
      location: "London Docklands",
      desc: "Premium waterfront development featuring 124 luxury apartments with stunning river views",
      features: ["24/7 Concierge", "Gym & Spa", "Secure Parking", "Riverside Gardens"],
      img: "/api/placeholder/400/320",
      status: "Fully Managed"
    },
    {
      title: "The Victoria Commercial Centre",
      type: "Commercial",
      location: "Manchester City Centre",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "/api/placeholder/400/320",
      status: "Block Management"
    },
    {
      title: "Heritage Court Development",
      type: "Mixed Use",
      location: "Birmingham",
      desc: "Historic conversion featuring luxury apartments and boutique retail spaces in the heart of the city",
      features: ["Period Features", "Courtyard Garden", "Secure Entry", "Retail Space"],
      img: "/api/placeholder/400/320",
      status: "Full Management"
    }
  ];

  const filteredProperties = selectedType === 'All' 
    ? properties 
    : properties.filter(property => property.type === selectedType);

  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

      {/* Header */}
      <header className={`bg-white border-b border-[#eae7e6] sticky top-0 z-40 transition-transform duration-300 ${
  isHidden ? '-translate-y-full' : 'translate-y-0'
}`}>    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center h-20">
        {/* Logo */}
        <div className="w-40"> 
          <Link to="/" className="flex items-center group cursor-pointer">
            <img 
              src="/header-logo.png" 
              alt="Nova Asset Management" 
              className="h-12 transform group-hover:scale-105 transition-transform duration-200"
            />
          </Link>
        </div>
        {/* Centered Desktop Navigation */}
        <nav className="hidden md:flex flex-1 justify-center">
          <div className="flex space-x-12">
            {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
              <Link
                key={item}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium transition-colors duration-200 py-2 ${
                  (item === "Home" && location.pathname === "/") || 
                  (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                    ? "text-[#b2a5a1]" 
                    : ""
                }`}
                onMouseEnter={() => setHoveredLink(item)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item}
                <span 
                  className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] transform origin-left transition-transform duration-200 ${
                    ((item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                    hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                  }`}
                />
              </Link>
            ))}
          </div>
        </nav>
        {/* Mobile menu button */}
        <div className="w-40 flex justify-end">
          <button 
            className="md:hidden relative group p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="flex flex-col space-y-1.5">
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>
        </div>
      </div>
    </div>

    {/* Mobile Navigation */}
    <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-64' : 'max-h-0'}`}>
      <div className="px-4 py-3 space-y-2">
        {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
          <Link
            key={item}
            to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
              (item === "Home" && location.pathname === "/") || 
              (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                ? "text-[#b2a5a1] bg-[#f7f5f5]"
                : "text-[#8c7f7c]"
            }`}
            onClick={() => setIsMenuOpen(false)}
          >
            {item}
          </Link>
        ))}
      </div>
    </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-[#8c7f7c] to-[#6a5f5c] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          {/* Page Title */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white sm:text-5xl">Our Properties</h1>
            <p className="mt-4 text-xl text-neutral-200">
              Discover our portfolio of expertly managed properties
            </p>
          </div>

          {/* Type Filter */}
          <div className="flex justify-center gap-4 mb-12">
            {['All', 'Residential', 'Commercial', 'Mixed Use'].map((type) => (
              <button
                key={type}
                onClick={() => setSelectedType(type)}
                className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 
                  ${selectedType === type 
                    ? 'bg-white text-[#8c7f7c]' 
                    : 'bg-white/10 text-white hover:bg-white/20'}`}
              >
                {type}
              </button>
            ))}
          </div>

          {/* Properties Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProperties.map((property, i) => (
              <div 
                key={i}
                className="bg-white/10 backdrop-blur-lg rounded-xl shadow-md border border-white/20 overflow-hidden hover:shadow-xl transition-all duration-300"
              >
                {/* Image Container */}
                <div className="relative">
                  <div className="relative pb-[65%] overflow-hidden">
                    <img 
                      src={property.img}
                      alt={property.title}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                  {/* Property Type Badge */}
                  <div className="absolute top-4 left-4">
                    <span className="px-3 py-1 bg-white/90 backdrop-blur-sm text-[#8c7f7c] text-sm font-medium rounded-full">
                      {property.type}
                    </span>
                  </div>
                  {/* Status Badge */}
                  <div className="absolute top-4 right-4">
                    <span className="px-3 py-1 bg-[#b2a5a1]/90 backdrop-blur-sm text-white text-sm font-medium rounded-full">
                      {property.status}
                    </span>
                  </div>
                </div>

                {/* Content */}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {property.title}
                  </h3>
                  <p className="text-neutral-200 flex items-center mb-4">
                    <MapPin className="h-4 w-4 mr-1" />
                    {property.location}
                  </p>
                  <p className="text-neutral-200 mb-6">{property.desc}</p>

                  {/* Features */}
                  <div className="mb-6">
                    <div className="flex flex-wrap gap-2">
                      {property.features.map((feature, index) => (
                        <span 
                          key={index}
                          className="px-3 py-1 bg-white/10 text-neutral-200 text-sm rounded-full"
                        >
                          {feature}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Action Button */}
                  <button
                    onClick={() => triggerNotification()}
                    className="w-full px-4 py-2 bg-white text-[#8c7f7c] text-sm font-medium rounded-lg hover:bg-white/90 transition-colors duration-200 flex items-center justify-center group"
                  >
                    View Details
                    <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#2c2827]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6">
        <p className="text-base text-[#968a87]">&copy; 2024 Nova Asset Management Ltd. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PropertiesPage;